import { Directive, HostBinding, computed, inject, input } from '@angular/core';

import { BUTTON_OPTIONS, ButtonColor, ButtonFill, ButtonSize } from './button.options';

/** Base button. */
@Directive()
export abstract class ButtonBase {
	private readonly options = inject(BUTTON_OPTIONS);

	/** Button size. */
	public readonly size = input<ButtonSize>(this.options.size);

	/** Button color. */
	public readonly color = input<ButtonColor>(this.options.color);

	/** Button fill mode. */
	public readonly fill = input<ButtonFill>(this.options.fill);

	private readonly classes = computed(() => `wndr-button ${this.size()} ${this.color()} ${this.fill()}`);

	@HostBinding('class')
	private get _classes(): string {
		return this.classes();
	}
}
