import { Provider } from '@angular/core';
import { createToken } from '@wndr/common/core/utils/miscellaneous/create-token';
import { provideOptions } from '@wndr/common/core/utils/miscellaneous/provide-options';

/** Button size. */
export type ButtonSize = 'small' | 'medium' | 'large';

/** Button color. */
export type ButtonColor = 'primary' | 'secondary' | 'warn' | 'success';

/** Button fill mode. */
export type ButtonFill = 'solid' | 'clear' | 'outline';

/** Button options. */
export type ButtonOptions = {

	/** Size. */
	readonly size: ButtonSize;

	/** Color. */
	readonly color: ButtonColor;

	/** Fill. */
	readonly fill: ButtonFill;
};

/** Button default options. */
export const BUTTON_DEFAULT_OPTIONS: ButtonOptions = {
	size: 'large',
	color: 'primary',
	fill: 'solid',
};

/** Injection token for the button options. */
export const BUTTON_OPTIONS = createToken(BUTTON_DEFAULT_OPTIONS, 'Button options');

/**
	* Provide button options.
	* @param options Button options.
	*/
export function provideButtonOptions(options: Partial<ButtonOptions>): Provider {
	return provideOptions(BUTTON_OPTIONS, options, BUTTON_DEFAULT_OPTIONS);
}
