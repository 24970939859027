import { InjectionToken } from '@angular/core';

/**
 * Wrapper function to create injection token.
 * @param defaults Default value.
 * @param description Description for the token.
 */
export function createToken<T>(defaults: T, description = ''): InjectionToken<T> {
	return createTokenFromFactory(() => defaults, description);
}

/**
 * Create token from factory.
 * @param factory Factory.
 * @param description Description for the token.
 */
export function createTokenFromFactory<T>(factory: () => T, description = ''): InjectionToken<T> {
	return new InjectionToken<T>(description, { factory });
}
